@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');

*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    overflow-x: inherit;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI;
    /* font-family: Gilroy,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    height: 100%;
    width: 100%;

    /* background-color: #ffab91; */
    /* background-image: repeating-linear-gradient(-30deg, white 0%, grey 50%, white); */
}

.iframe-size {
    height: calc(100vh - 56px);
    width: calc(100vw - 19px);
}

.mobile-background {
    /* background-color: #4A4A4A; */
    background-color: white;
    /* background-image: repeating-linear-gradient(-30deg, white 0%, grey 50%, white); */
}

.desktop-background {
    background-color: white;
}

.MuiChip-label {
    font-size: 12px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.MuiChip-filled {
    border-radius: 3px !important;
    box-shadow: 0px 0px #888888;
    background-color: gray;
}

.MuiCardMedia-img {
    object-fit: contain !important;
}

/* hamburger menu right alignment */
/* .MuiMenu-paper{
    right:0 !important;
} */

#watermark {
    position: fixed;
    top: calc(100vh/3);
    left: calc(100vw/2 - 140px);
    opacity: 0.5;
    z-index: 99;
    color: white;
    font-size: 2em;
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 10px #aaa, 0 0 10px #aaa, 0 0 10px #aaa, 0 0 10px #aaa, 0 0 10px #aaa
}